<template>
  <v-card>
    <v-card-text style="margin-top:20px">
      <eval-item v-for="{ name, value } in ratings"
        :key="name"
        :editable="editable"
        :label="name"
        :value="value"
        @update="(val) => updateRating(name, val)" />
      <template v-if="Array.isArray(comment)">
        <h2 style="margin-bottom:10px">Comments</h2>
        <v-textarea v-for="({ comment, name }, index) in comment" :key="'comment-' + index" :label="name" :value="comment" rows="2" readonly auto-grow></v-textarea>
      </template>
      <v-textarea v-else v-model="myComment" :readonly="!editable" label="General Comments" outlined></v-textarea>
    </v-card-text>
    <v-card-actions v-if="editable">
      <v-btn text color="success" @click="$emit('save', evaluation)">Save Evaluation</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  components: {
    EvalItem: () => import('./eval-item')
  },
  props: {
    ratings: {
      type: Array,
      required: true
    },
    comment: {
      type: [String, Array],
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const isSummary = computed(() => Array.isArray(props.comment))

    const myComment = ref(props.comment)
    watch(myComment, () => {
      emit('comment', myComment.value)
    })

    function updateRating (name, value) {
      emit('updateRating', { name, value })
    }

    return {
      isSummary,
      myComment,
      updateRating
    }
  }
}
</script>
